import React, { useState } from "react";
import "./ProgressBar.css";

export default function ProgressBar() {
  const [filled] = useState(10);

  return (
      <div className="progressbar">
        <div style={{
          height: "100%",
          width: `${filled}%`,
          backgroundColor: "#ff7293",
          transition:"width 0.5s"
        }}></div>
        <span className="progressPercent">{ filled }%</span>
      </div>
  );
}
