import "./App.css";
import Logo from '../src/assets/img/logo.png';
import DevLogo from '../src/assets/img/dev-logo.png'
import LogoPolygon from '../src/assets/img/logo-polygon.png'
import ProgressBar from "./components/ProgressBar/ProgressBar";

function App() {
  

  return (
    <>
      <div className="app">
        <img className="logo" src={Logo} alt="Logo" />
      </div>
      <div className="dev-logo">
        <img src={DevLogo} alt="Dev Logo" />
      </div>
      <div>
        <h2 className="text-building">Em construção...</h2>
      </div>
      <div className="progress-bar">
        <ProgressBar />
      </div>
      <div className="footer">
        <img src={LogoPolygon} alt="Polygon Logo" />
        <h3>Team Polygon</h3>
      </div>
    </>
    
  );
}

export default App;
